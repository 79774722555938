import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutsModule } from "./layouts";
import { AuthGuard } from "./common/guards/auth.guard";


const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule)},
  { path: 'home/stats', loadChildren: () => import('./stats/stats.module').then(m => m.StatsModule), canActivate: [AuthGuard] },
  { path: 'home/orders', loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule), canActivate: [AuthGuard] },
  { path: 'home/statuses', loadChildren: () => import('./statuses/statuses.module').then(m => m.StatusesModule), canActivate: [AuthGuard] },
  { path: '**', redirectTo: 'home/orders' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    LayoutsModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

<div class="mdl-card mdl-card__blank-layout-card mdl-shadow--2dp">
  <div class="mdl-card__supporting-text color--dark-gray">
    <div class="mdl-grid">
      <div class="mdl-cell mdl-cell--12-col mdl-cell--4-col-phone">
        <span class="lfm_title-text text-color--white mdl-typography--font-bold">
          <img class="lfm_main-logo" src="assets/images/lfmcorporatelogo_short.png">
          LFM Production System
        </span>
      </div>
      <div class="mdl-cell mdl-cell--12-col mdl-cell--4-col-phone">
        <span class="blank-layout-card-name text-color--white">Iniciar Sessão</span>
        <span class="blank-layout-card-secondary-text text-color--smoke">Introduza o seu Login (Mesmo do PHC)</span>
      </div>

      <form class="login-form" [formGroup]="loginForm" (submit)="login()" autocomplete="off" novalidate>
        <div class="mdl-cell mdl-cell--12-col mdl-cell--4-col-phone">
          <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label full-size"
               [class.is-invalid]="username && username.invalid && (username.dirty || username.touched)"
               [class.is-valid]="username && username.valid && (username.dirty || username.touched)">
            <input formControlName="username"
                   (change)="onInputChange($event)"
                   class="mdl-textfield__input" type="text" id="username">
            <label class="mdl-textfield__label" for="username">Username</label>

            <div *ngIf="username && username.invalid && (username.dirty || username.touched)">
              <span *ngIf="username.errors && username.errors.required" class="mdl-textfield__error">
                Username Obrigatório. <span class="color-text--orange"> Por favor, introduza o seu username.</span>
              </span>
            </div>
          </div>

          <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label full-size"
               [class.is-invalid]="password && password.invalid && (password.dirty || password.touched)"
               [class.is-valid]="password && password.valid && (password.dirty || password.touched)"
               id="forPass">
            <input formControlName="password"
                   (change)="onInputChange($event)"
                   class="mdl-textfield__input" type="password" id="password">
            <label class="mdl-textfield__label" for="password">Password</label>
            <div *ngIf="password && password.invalid && (password.dirty || password.touched)">
              <span *ngIf="password.errors && password.errors.required" class="mdl-textfield__error">
                Password Obrigatório. <span class="color-text--orange"> Por favor, introduza a sua password.</span>
              </span>
            </div>
          </div>

          <div class="full-size color-text--red" *ngIf="error">Login Inválido</div>

        </div>
        <div class="mdl-cell mdl-cell--12-col mdl-cell--4-col-phone submit-cell">
          <div class="mdl-layout-spacer"></div>
          <button class="mdl-button mdl-js-button mdl-button--raised button--colored-light-blue"
                  type="submit" [disabled]="loginForm.invalid">
            ENTRAR
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="mdl-layout mdl-js-layout mdl-layout--fixed-drawer mdl-layout--fixed-header has-drawer">
  <div class="mdl-layout__header">
    <base-page-top>
      <div class="mdl-layout-spacer"></div>
      <div class="avatar-dropdown" id="icon" (click)="openUserMenu($event)">
        <span class="lfm_user_name_top_bar" style="">{{ user.name }}</span>
        <span><ngx-avatar name="{{ user.name }}"></ngx-avatar></span>
      </div>
      <div style="position: fixed"
           [style.left]="userMenuPosition.x"
           [style.top]="userMenuPosition.y"
           [matMenuTriggerFor]="menu"
           #userMenu>
      </div>
      <mat-menu class="mdl-card user-mat-menu" xPosition="after" yPosition="above" #menu="matMenu">
          <div class="custom-user-info">
            <span class="mdl-list__item-primary-content">
                <span>{{ user.name.trim() }} ({{ user.username.trim() }}) </span>
                <span class="mdl-list__item-sub-title">{{ user.email.trim() }}</span>
            </span>
          </div>
          <button class="mdl-button mdl-js-button mdl-js-ripple-effect button--colored-white"
                  (click)="logout()"
                  mat-menu-item>
            <i class="material-icons mdl-list__item-icon text-color--secondary">exit_to_app</i>
            Log out
          </button>
      </mat-menu>
    </base-page-top>
  </div>
  <div class="mdl-layout__drawer">
    <app-sidebar></app-sidebar>
  </div>
  <main class="mdl-layout__content">
    <router-outlet></router-outlet>
  </main>
</div>

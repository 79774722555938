import {ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DREAMBOOKS_EDITOR_API_MODULE_CONFIG,
  DreambooksEditorApiModuleConfig
} from "./dreambooks-editor-api-module-config";
import {
  DreambooksEditorApiConfig,
  dreambooksEditorApiConfigFactory
} from "./dreambooks-editor-api-config";
import {HTTP_INTERCEPTORS} from "@angular/common/http";

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
})
export class DreambooksEditorApiModule {
  static forRoot(config: DreambooksEditorApiModuleConfig = {}): ModuleWithProviders<any> {
    return {
      ngModule: DreambooksEditorApiModule,
      providers: [
        {
          provide: DREAMBOOKS_EDITOR_API_MODULE_CONFIG,
          useValue: config,
        },
        {
          provide: DreambooksEditorApiConfig,
          useFactory: dreambooksEditorApiConfigFactory,
          deps: [DREAMBOOKS_EDITOR_API_MODULE_CONFIG]
        }
      ]
    }
  }
}

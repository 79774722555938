// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiBaseUrl: 'https://api.production.dev.corp.lfmcorporate.com',
  dreambooksEditorApiBaseUrl: 'https://api.v3.dreambooksworld.com/external/lfm_prod_system',
  lfmExpressApiBaseUrl: 'https://cms.lfmpro.com/LFMProdSystemProxy.php',
  dreambooksEditorApiToken: '6e62ed244da81c0ae60b1039041512d5',
  lfmExpressApiToken: '468c097772b5008e586f724b6c7d9223'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

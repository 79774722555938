import { NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";

import { MatSliderModule } from '@angular/material/slider';
import { AppRoutingModule } from './app-routing.module';
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { LoginModule } from "./login/login.module";

import { AvatarModule } from 'ngx-avatar';
import { ApiModule } from "./api/api.module";
import { environment } from "../environments/environment";
import { DreambooksEditorApiModule } from "./dreambooks-editor-api/dreambooks-editor-api.module";
import { LfmExpressApiModule } from "./lfm-express-api/lfm-express-api.module";
import { AuthInterceptor } from "./common/interceptors/auth.interceptor";
import { ActionMessageComponent } from './components/action-message/action-message.component';
import { ActionQuestionComponent } from './components/action-question/action-question.component';
import { MatDialogModule } from "@angular/material/dialog";
import { ThemeModule } from "../theme";

@NgModule({
  declarations: [
    AppComponent,
    ActionMessageComponent,
    ActionQuestionComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatSliderModule,
    AppRoutingModule,
    MatTabsModule,
    MatToolbarModule,
    LoginModule,
    HttpClientModule,
    AvatarModule,
    ApiModule.forRoot({
      apiUrl: environment.apiBaseUrl,
    }),
    DreambooksEditorApiModule.forRoot({
      apiUrl: environment.dreambooksEditorApiBaseUrl,
    }),
    LfmExpressApiModule.forRoot({
      apiUrl: environment.lfmExpressApiBaseUrl,
    }),
    MatDialogModule,
    ThemeModule
  ],
  providers: [    {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }



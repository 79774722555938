import {DreambooksEditorApiModuleConfig} from "./dreambooks-editor-api-module-config";

export class DreambooksEditorApiConfig {
  apiUrl: string;

  static fromModuleConfig(config: DreambooksEditorApiModuleConfig) {
    return Object.assign(new DreambooksEditorApiConfig(), config);
  }

  buildUrl(...parts: string[]): string {
    return `${this.apiUrl}${this.apiUrl.endsWith('/') ? '' : '/'}${parts.join('/')}`;
  }
}

export function dreambooksEditorApiConfigFactory(config: DreambooksEditorApiModuleConfig): DreambooksEditorApiConfig {
  return Object.assign(new DreambooksEditorApiConfig(), config);
}

import {Component, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from "../../common/services/auth.service";
import { LoginService } from "../../api/services/login.service";
import { UserModel } from "../../api/models/user.model";
import { MatMenuTrigger } from "@angular/material/menu";

@Component({
  selector: 'app-common-layout',
  templateUrl: './common-layout.component.html',
})
export class CommonLayoutComponent implements OnInit {

  @ViewChild(MatMenuTrigger) userMenu: MatMenuTrigger;
  userMenuPosition = { x: '0px', y: '0px' };
  public user:UserModel;

  constructor(private loginService: LoginService,
              private router: Router,
              private authService: AuthService) {

  }

  public ngOnInit() {
    this.user = this.authService.getUser();
    console.log(this.user);
  }

  public logout() {
    this.loginService.logout();
    this.router.navigate(['/login']);
  }

  openUserMenu(event: any) {
    event.preventDefault();
    this.userMenuPosition.x = event.clientX + 'px';
    this.userMenuPosition.y = event.clientY + 'px';
    this.userMenu.openMenu()
  }

}

import {ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {API_MODULE_CONFIG, ApiModuleConfig} from "./api-module-config";
import {ApiConfig, apiConfigFactory} from "./api-config";

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
})
export class ApiModule {
  static forRoot(config: ApiModuleConfig = {}): ModuleWithProviders<any> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: API_MODULE_CONFIG,
          useValue: config,
        },
        {
          provide: ApiConfig,
          useFactory: apiConfigFactory,
          deps: [API_MODULE_CONFIG]
        }
      ]
    }
  }

}


import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {UserModel} from "../../api/models/user.model";

const tokenName = 'token';
const userName = 'user';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private user:UserModel;

  constructor() {
  }

  public get isLoggedIn(): boolean {
    if (localStorage.getItem(userName)) {
      return true;
    }
    return false;
  }

  public getAuthToken(): string {
    return localStorage.getItem(tokenName);
  }

  public setAuthToken(accessToken: string | undefined):void {
    localStorage.removeItem(tokenName);
    if (accessToken) {
      localStorage.setItem(tokenName, accessToken);
    }
  }

  public getUser(): UserModel | undefined {
    if (localStorage.getItem(userName)) {
      this.user = JSON.parse(localStorage.getItem(userName));
    }
    return this.user;
  }

  public setUser(user: UserModel | undefined): void {
    localStorage.removeItem(userName);

    if (user) {
      localStorage.setItem(userName, JSON.stringify(user));
    }
  }

  public clearSession() {
    localStorage.clear();
  }
}

import {ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {LFM_EXPRESS_API_MODULE_CONFIG, LfmExpressApiModuleConfig} from "./lfm-express-api-module-config";
import { LfmExpressApiConfig, lfmExpressApiConfigFactory} from "./lfm-express-api-config";



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class LfmExpressApiModule {
  static forRoot(config: LfmExpressApiModuleConfig = {}): ModuleWithProviders<any> {
    return {
      ngModule: LfmExpressApiModule,
      providers: [
        {
          provide: LFM_EXPRESS_API_MODULE_CONFIG,
          useValue: config,
        },
        {
          provide: LfmExpressApiConfig,
          useFactory: lfmExpressApiConfigFactory,
          deps: [LFM_EXPRESS_API_MODULE_CONFIG]
        }
      ]
    }
  }
}

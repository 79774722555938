import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BlankLayoutCardComponent } from '../../../components/blank-layout-card';
import { LoginModel } from "../../../api/models/login.model";
import { LoginService } from "../../../api/services/login.service";

@Component({
  selector: 'app-login',
  styleUrls: ['../../../components/blank-layout-card/blank-layout-card.component.scss'],
  templateUrl: './login.component.html',
})
export class LoginComponent extends BlankLayoutCardComponent implements OnInit {
  public loginForm: UntypedFormGroup;
  public username;
  public password;
  public loginData: LoginModel;
  public emailPattern = '^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$';
  public error:any;

  constructor(public loginService: LoginService,
              public fb: UntypedFormBuilder,
              public router: Router) {
    super();

    this.loginForm = this.fb.group({
      password: new UntypedFormControl('', Validators.required),
      username: new UntypedFormControl('', Validators.required),
    });

    this.username = this.loginForm.get('username');
    this.password = this.loginForm.get('password');
  }

  public ngOnInit() {
    //this.loginService.logout();
    this.loginForm.valueChanges.subscribe(() => {
      this.error = null;
    });
  }

  public login() {
    this.error = null;
    if (this.loginForm.valid) {
      this.loginService.login(this.loginForm.getRawValue())
        .subscribe(res => this.router.navigate(['/home/orders']),
          error => this.error = error.message);
    }
  }

  public onInputChange(event: any) {
    event.target.required = true;
  }
}

<div class="custom-title-with-left-margin mdl-cell--12-col-desktop mdl-cell--12-col-tablet mdl-cell--4-col-phone">
  <h3 class="mdl-color-text--primary-contrast" mat-dialog-title>Acção a efetuar...</h3>
</div>
<mat-dialog-content>
  <div class="mdl-cell mdl-cell--12-col-desktop mdl-cell--12-col-tablet mdl-cell--4-col-phone">
    <base-card>
      <base-card-body class="no-padding">
        <p *ngIf="(data.actionType === statusActionsType.SHELF)">Coloque o item na celula:</p>
        <p><b> {{data.actionResult}}</b></p>
      </base-card-body>
    </base-card>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end" >
  <button class="mdl-button mdl-js-button mdl-js-ripple-effect mdl-button--raised button--colored-light-blue"
          mat-button
          [mat-dialog-close]="data"
          cdkFocusInitial>
    <i class="material-icons">check</i>OK
  </button>
</mat-dialog-actions>

import { Component, Input } from '@angular/core';

import { SidebarComponent as BaseSidebarComponent } from 'theme/components/sidebar';

@Component({
  selector: 'app-sidebar',
  styleUrls: ['../../../theme/components/sidebar/sidebar.component.scss', './sidebar.component.scss'],
  templateUrl: '../../../theme/components/sidebar/sidebar.component.html',
})
export class SidebarComponent extends BaseSidebarComponent {
  public title = 'LFM Prod';
  public menu = [
    {
      name: 'Encomendas',
      children: [
        { name: 'Em Produção', link: '/home/orders/in-production' },
        { name: 'Para Produção', link: '/home/orders/for-production' },
        { name: 'Produzidas', link: '/home/orders/produced'  },
        { name: 'Procurar...', link: '/home/orders/search'  }
        ],
      icon: 'view_comfy'
    },
    {
      name: 'Picagem Estados',
      link: '/home/statuses/set-order-status',
      icon: 'done'
    },
    {
      name: 'Estatisticas',
      link: '/home/stats/sectors-stats',
      icon: 'dashboard'
    },
  ];
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/index';

import {
  HttpContext,
  HttpContextToken,
  HttpEvent,
  HttpHandler, HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {ApiType} from "../models/api-type.enum";
import {environment} from "../../../environments/environment";
import {AuthService} from "../services/auth.service";

const API_TYPE = new HttpContextToken<Number>(() => ApiType.Default);

export function getDefaultApi() {
  return new HttpContext().set(API_TYPE, ApiType.Default);
}

export function getDreambooksEditorApi() {
  return new HttpContext().set(API_TYPE, ApiType.DreambooksEditor);
}

export function getLfmExpressApi() {
  return new HttpContext().set(API_TYPE, ApiType.LfmExpress);
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization token for full requests

    console.log('auth interceptor');
    console.log(request);
    console.log(request.context.get(API_TYPE));

    if(request.context.get(API_TYPE) === ApiType.Default) {
      request = request.clone({
        setHeaders: { Authorization: `${this.auth.getAuthToken()}` }
      });
    }

    if(request.context.get(API_TYPE) === ApiType.DreambooksEditor) {
      request = request.clone({
        setHeaders: { 'x-auth-token': `${environment.dreambooksEditorApiToken}`}
      });
    }

    if(request.context.get(API_TYPE) === ApiType.LfmExpress) {
      request = request.clone({
        setParams: { 'access_token': `${environment.lfmExpressApiToken}`}
      });
    }

    return next.handle(request);
  }
}

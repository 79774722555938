import {LfmExpressApiModuleConfig} from "./lfm-express-api-module-config";

export class LfmExpressApiConfig {
  apiUrl: string;

  static fromModuleConfig(config: LfmExpressApiModuleConfig) {
    return Object.assign(new LfmExpressApiConfig(), config);
  }

  buildUrl(...parts: string[]): string {
    return `${this.apiUrl}${this.apiUrl.endsWith('/') ? '' : '/'}${parts.join('/')}`;
  }
}

export function lfmExpressApiConfigFactory(config: LfmExpressApiModuleConfig): LfmExpressApiConfig {
  return Object.assign(new LfmExpressApiConfig(), config);
}

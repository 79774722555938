import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ApiConfig} from "../api-config";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {LoginModel} from "../models/login.model";
import {UserModel} from "../models/user.model";
import {AuthService} from "../../common/services/auth.service";

@Injectable({
  providedIn: 'root'
})

export class LoginService {

  constructor(
    private httpClient: HttpClient,
    private apiConfig: ApiConfig,
    private authService: AuthService) {
  }

  public login(data: LoginModel): Observable<any> {
    return this.httpClient.post(this.apiConfig.buildUrl('login'), data)
      .pipe(
        map((user: UserModel) => {
          this.authService.setAuthToken(user.accessToken);
          this.authService.setUser(user);
          console.log(user);
          return user;
          }
        )
      );
  }

  public logout(): void {
    this.authService.clearSession();
  }

}

import {ApiModuleConfig} from "./api-module-config";
import {HttpContext, HttpContextToken} from "@angular/common/http";
import {ApiType} from "../common/models/api-type.enum";

export class ApiConfig {
  apiUrl: string;

  static fromModuleConfig(config: ApiModuleConfig) {
    return Object.assign(new ApiConfig(), config);
  }

  buildUrl(...parts: string[]): string {
    return `${this.apiUrl}${this.apiUrl.endsWith('/') ? '' : '/'}${parts.join('/')}`;
  }
}

export function apiConfigFactory(config: ApiModuleConfig): ApiConfig {
  return Object.assign(new ApiConfig(), config);
}
